import { useToast } from "../state/hooks";
import { connectorsByName } from "../utils/web3React";

const useAuth = () => {
  const { toastError } = useToast();

  const login = async (connectorID) => {
    const connector = connectorsByName[connectorID];
    console.log("---------->", connector);
    if (connector) {
      if (connectorID === "injected") {
        await connector.activate(1);
      } else {
        await connector.activate();
      }
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
  };

  const logout = async (connectorID) => {
    // console.log("disssss",connectorID)
    const connector = connectorsByName[connectorID];
    // console.log("disssss",connector)
    if (connector) {
      if (connector?.deactivate) {
        await connector.deactivate();
      } else {
        await connector.resetState();
      }
    } else {
      toastError("Can't find connector", "The connector config is wrong");
    }
  };

  return { login, logout };
};

export default useAuth;
